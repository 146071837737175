@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;

  height: 100vh;
  width: 100vw;

  height: -webkit-fill-available;
  width: -webkit-fill-available;
}
html {
  scroll-behavior: smooth;
}